export class DomainUtils {

    public static canChangeDomain() {
        // let h = window.location.hostname;
        return false;
    }

    public static getDefaultDomain(): string {
        return DomainUtils.toDomain('srv1');
    }

    public static getProxyDomain(): string {
        return DomainUtils.toDomain('srv2');
    }

    private static toDomain(prefix: string): string {
        let h = window.location.hostname;
        console.log('h = ' + h);
        if (h.includes('dev.lgtest.fun')) {
            return 'https://' + prefix + '.dev.lgtest.fun';
        } else if (h.includes('ya.zazer.mobi')) {
            return 'https://' + prefix + '.ya.zazer.mobi';
        } else if (h.includes('foto.zazer.mobi')) {
            return 'https://' + prefix + '.foto.zazer.mobi';
        } else if (h.includes('ok.zazer.mobi')) {
            return 'https://' + prefix + '.ok.zazer.mobi';
        } else if (h.includes('vk.zazer.mobi')) {
            return 'https://' + prefix + '.vk.zazer.mobi';
        } else if (h.includes('beeline.zazer.mobi')) {
            return 'https://' + prefix + '.beeline.zazer.mobi';
        } else if (h.includes('exe.zazer.mobi')) {
            return 'https://' + prefix + '.exe.zazer.mobi';
        } else if (h.includes('mystore.zazer.mobi')) {
            return 'https://' + prefix + '.mystore.zazer.mobi';
        }
        return h;
    }
}