import SockJS from "sockjs-client";
import Stomp, {Client} from "webstomp-client";
import {
    ChatMessageDeleteNotification,
    ChatNotification,
    MailNotification,
    PushNotificationType
} from "@/model/notification";
import ListenerManager from "@/managers/listener-manager";
import {Settings} from "@/constants/settings";
import PlayerStore from "@/store/player";
import {DomainUtils} from "@/utils/domain-utils";
import http from "@/axios";

export class NotificationManager {

    private static subscribedClanIds: number[] = [];

    private static stompClient: Client | undefined;

    private static chatListeners: ((room: string, n: ChatNotification) => unknown) [] = [];

    private static mailListeners: ((n: MailNotification) => unknown) [] = [];

    private static connectionListeners: (() => unknown) [] = [];

    private static chatMessageDeleteListeners: ((room: string, n: ChatMessageDeleteNotification) => unknown) [] = [];

    public static initNotifications(): void {
        let socket
        if (process.env.VUE_APP_IS_MOBILE_APP === 'true') {
            socket = new SockJS(`${process.env.VUE_APP_API_HOST}/api/websocket`);
        }
        else if (DomainUtils.canChangeDomain()) {
            let url = http.defaults.baseURL + '/api/websocket';
            console.log('websocket url = ' + url);
            socket = new SockJS(url);
        }
        else {
            socket = new SockJS("/api/websocket");
        }
        let stompClient = Stomp.over(socket);
        stompClient.debug = () => {};
        stompClient.connect(
            {},
            () => {
                NotificationManager.stompClient = stompClient;
                NotificationManager.subscribedClanIds = [];
                NotificationManager.subscribeBroadcast();
                NotificationManager.subscribePersonal();
                NotificationManager.subscribeClan();
            },
            (error: any) => {
                ListenerManager.callConnectionLostCallback();
                console.log(error);
            }
        );
    }

    private static subscribeBroadcast() {
        if (NotificationManager.stompClient) {
            NotificationManager.stompClient.subscribe("/stomp/broadcast", (notification: any) => {
                ListenerManager.callChatSeenCallback(true);
                NotificationManager.handleMessage(notification);
            });
        }
    }

    private static subscribePersonal() {
        if (NotificationManager.stompClient) {
            NotificationManager.stompClient.subscribe("/stomp/personal/" + PlayerStore.getClientUuid(), (notification: any) => {
                PlayerStore.SET_HAVE_NEW_MAIL();
                NotificationManager.handleMessage(notification);
            });
        }
    }

    public static subscribeClan() {
        if (NotificationManager.stompClient) {
            let player = PlayerStore.getPlayer();
            if (player.clanId && !NotificationManager.subscribedClanIds.includes(player.clanId)) {
                NotificationManager.subscribedClanIds.push(player.clanId);
                NotificationManager.stompClient.subscribe("/stomp/clan/" + String(player.clanId), (notification: any) => {
                    NotificationManager.handleMessage(notification, Settings.CLAN_CHAT_ROOM);
                });
            }
        }
    }

    private static handleMessage(notification: any, chatRoom: string = Settings.MAIN_CHAT_ROOM) {
        notification = JSON.parse(notification.body);
        switch (notification.notificationType) {
            case PushNotificationType.CHAT_MESSAGE:
                let chatNotification: ChatNotification = Object.assign(new ChatNotification(), notification);
                NotificationManager.chatListeners.forEach(listener => listener(chatRoom, chatNotification));
                break;
            case PushNotificationType.MAIL_MESSAGE:
                let mailNotification: MailNotification = Object.assign(new MailNotification(), notification);
                NotificationManager.mailListeners.forEach(listener => listener(mailNotification));
                break;
            case PushNotificationType.CHAT_MESSAGE_DELETE:
                let chatMessageDeleteNotification: ChatMessageDeleteNotification = Object.assign(new ChatMessageDeleteNotification(), notification);
                NotificationManager.chatMessageDeleteListeners.forEach(listener => listener(chatRoom, chatMessageDeleteNotification));
                break;
        }
    }

    public static addChatNotificationListener(listener: (room: string, n: ChatNotification) => unknown): void {
        this.chatListeners.push(listener);
    }

    public static removeChatNotificationListener(listener: (room: string, n: ChatNotification) => unknown): void {
        this.chatListeners = this.chatListeners.filter(single => {
            return single !== listener;
        });
    }

    public static addMailNotificationListener(listener: (n: MailNotification) => unknown): void {
        this.mailListeners.push(listener);
    }

    public static removeMailNotificationListener(listener: (n: MailNotification) => unknown): void {
        this.mailListeners = this.mailListeners.filter(single => {
            return single !== listener;
        });
    }

    public static addChatMessageDeleteNotificationListener(listener: (room: string, n: ChatMessageDeleteNotification) => unknown): void {
        this.chatMessageDeleteListeners.push(listener);
    }

    public static removeChatMessageDeleteNotificationListener(listener: (room: string, n: ChatMessageDeleteNotification) => unknown): void {
        this.chatMessageDeleteListeners = this.chatMessageDeleteListeners.filter(single => {
            return single !== listener;
        });
    }

    public static addConnectionListener(listener: () => unknown): void {
        this.connectionListeners.push(listener);
    }

    public static removeConnectionListener(listener: () => unknown): void {
        this.connectionListeners = this.connectionListeners.filter(single => {
            return single !== listener;
        });
    }
}
