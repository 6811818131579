import {Creature} from "@/store/level/types";
import {AccessLevel, UserSource} from "@/store/player/types";
import {ClanControlAction} from "@/store/clan/types";
import {GiftInfo} from "@/store/gift/types";
import {ArenaLeague} from "@/store/arena/types";

export interface ProfileState {
}
export class Profile {
    id!: number;
    userSource!: UserSource;
    displayingName!: string;
    accessLevel!: AccessLevel;

    creatures!: Creature[];
    badges!: Badge[];

    victoriesCount!: number;
    defeatsCount!: number;
    battlesTotal!: number;

    bestVictoryCreatureId!: string;
    bestVictoryStair!: number;
    completedTasks!: number;
    completedScenarioTasks!: number;
    startedGameAt!: string;
    lastActivityAt!: string;

    level!: number;
    stair!: number;

    properProfile!: boolean;

    playerActions!: PlayerAction[];
    clanControlActions!: ClanControlAction[];

    arenaPosition!: number;
    arenaLeague!: ArenaLeague;
    clanStatus!: string;

    ban: string | undefined;

    supporterId!: number;

    gifts!: GiftInfo[];
    giftsCount!: number;

    goodPoints!: number;
    evilPoints!: number;
}

export class Badge {
    id!: number;
    rewardId!: string;
    name!: string;
    description!: string;
    badgeNew!: boolean;
}

export enum PlayerAction {
    BLOCK = 'BLOCK',
    UNBLOCK = 'UNBLOCK'
}
