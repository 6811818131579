import {Player, PlayerMarker} from "@/store/player/types";
import {Gender} from "@/model/gender";
import PlayerStore from "@/store/player";

export default class PlayerUtils {

    public static getGender(player: Player): Gender {
        let companionImage: string = player.companionImage;
        if (companionImage != null && companionImage.includes("female")) {
            return Gender.female;
        }
        return Gender.male;
    }

    public static hasMarker(marker: String): Boolean {
        return PlayerStore.getPlayer().playerMarkers.some((m: PlayerMarker) => m.playerMarker == marker);
    }

}
